<template>
  <div id="container">
    <div class="header">
      <van-nav-bar title="支付成功" style="background: #01978a" fixed />
    </div>

    <div class="content">
      <!-- <div class="logo">
 <img src="../../src/assets/scc.png" class="scc"  alt="">
 <view class="resuil">办理成功</view>
  </div>



<view class="btn" catchtap="re_home">返回首页</view> -->

      <div>
        <img src="../../src/assets/scc.png" class="scc" alt="" />
      </div>
      <div class="resuil">缴费成功</div>
    </div>
    <div class="footer">
      <div style="margin-left: 20vw; margin-right: 20vw; margin-top: 6vw">
        <van-button
          block
          plain
          type="primary"
          style="border: 2px solid #3bb270"
          @click="GoPay()"
        >
          <h3 style="color: #3bb270">继续缴费</h3>
        </van-button>
        <van-button
          block
          plain
          type="primary"
          style="border: 2px solid #3bb270; margin-top: 5vw"
          @click="GoHome()"
        >
          <h3 style="color: #3bb270">返回首页</h3>
        </van-button>
      </div>
      <!-- <div class="btn" catchtap="re_home">返回首页</div> -->
    </div>
  </div>
</template>

<script>
// import { Toast } from 'vant';
export default {
  data() {
    return {};
  },
  created() {
    document.title = "学生管理平台缴费";
    // let Name = this.$route.query.Name
    sessionStorage.setItem("Name", this.$route.query.Name);
    sessionStorage.setItem("Code", this.$route.query.Code);
    sessionStorage.setItem("IDcard",  this.$route.query.IDcard); 
    sessionStorage.setItem("Mobile",  this.$route.query.Mobile);

  },
  methods: {
    GoHome() {
      this.$router.push("/PaymentInfo");
    },
    GoPay() {
      this.$router.push("/Payment");
    },
    onClickLeft() {
      console.log("返回");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  /* height: 6vh; */
  color: cornsilk;
  font-size: 2vh;

  margin-left: 3vw;
}

.content {
  margin-top: 6vh;
  height: 50vh;
  padding-top: 50px;
  text-align: center;
}

.scc {
  margin-top: 100rpx;
  width: 160rpx;
  height: 160rpx;
}
.resuil {
  margin-top: 20rpx;
  font-weight: 600;
  letter-spacing: 3rpx;
  font-size: 36rpx;
}
.btn {
  width: 280rpx;
  letter-spacing: 3rpx;
  height: 70rpx;
  font-weight: 600;
  line-height: 70rpx;
  border: 2px solid #3bb270;
  color: #3bb270;
  border-radius: 10rpx;
  position: absolute;
  bottom: 200rpx;
  left: 50%;
}

.footer {
  /* position: fixed;
  left: 0px; */
  height: 10vh;
  width: 100%;

  text-align: center;
}

.header /deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 16px;
}
.header /deep/ .van-nav-bar .van-icon {
  color: white !important;
}
</style>